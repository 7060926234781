@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(./images/suelo_radiante.png) fixed center center;
  background-size: cover;
}

/* Estilos CSS */
.phone-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1e3a8a;
  color: #1e3a8a;
  padding: 10px 10px;
  border-radius: 100%;
  text-decoration: none;
}

.phone-link a {
  color: #1e3a8a;
  text-decoration: none;
}



